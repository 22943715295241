<template>
  <layout name="OrganizLayout">
    <div class="Home">
      <section>
        <div class="uk-container">
          <div>
            <div class="uk-grid">
              <div class="uk-width-1-2">
                <router-link
                  class="uk-button uk-button-small uk-button-primary"
                  title="구매자료 리스트"
                  tag="a"
                  :to="{ name: 'datamarket'}">
                  <span uk-icon="icon: chevron-left; ratio: .75;" class="uk-icon"></span> 구매자료 리스트
                </router-link>
              </div>
              <div class="uk-width-1-2">
                <p class="uk-text-right soctial">
                  <social-sharing :url="`/organization/question/`+question.id"
                                  :title="question.title"
                                  ::description="question.description"
                                  :quote="question.title"
                                  :hashtags="question.keyword"
                                  twitter-user="vuejs"
                                  :media="question.imageurl"
                                  inline-template>
                    <div>
                      <network style="background:#09f" network="facebook">
                        <i class="fa fa-fw fa-facebook"></i>
                      </network>
                      <network style="background:#d34836" network="googleplus">
                        <i class="fa fa-fw fa-google-plus"></i>
                      </network>
                      <network style="background:#0073b1" network="linkedin">
                        <i class="fa fa-fw fa-linkedin"></i>
                      </network>
                      <network style="background:#e60023" network="pinterest">
                        <i class="fa fa-fw fa-pinterest"></i>
                      </network>

                      <network style="background:#1da1f2" network="twitter">
                        <i class="fa fa-fw fa-twitter"></i>
                      </network>

                    </div>
                  </social-sharing>
                </p>
              </div>
            </div>

            <div class="uk-grid">
              <div class="uk-width-3-5@m">
                <fieldset data-uk-margin class="uk-width-expand@m">
                  <div
                    class="uk-grid-collapse uk-child-width-1-1"
                    uk-slideshow="finite: true; ratio: 4:3;"
                    uk-grid
                  >
                    <div>
                      <ul class="uk-slideshow-items" uk-lightbox>
                        <li v-for="(itemmedia, index) in question.question_datas" :key="index">
                          <a
                            class="uk-padding-small tm-media-box tm-media-box-zoom"
                            :href="`/uploadingDir/`+itemmedia.media.filename"
                          >
                            <figure class="tm-media-box-wrap">
                              <vue-plyr
                                data-width="500"
                                data-height="300"
                                v-if="itemmedia.media.mediatype === 'video'"
                              >
                                <video poster :src="`/uploadingDir/`+itemmedia.media.filename">
                                  <source
                                    :src="`/uploadingDir/`+itemmedia.media.filename"
                                    type="video/mp4"
                                    size="720"
                                  >
                                  <source
                                    :src="`/uploadingDir/`+itemmedia.media.filename"
                                    type="video/mp4"
                                    size="1080"
                                  >
                                  <track
                                    kind="captions"
                                    label="English"
                                    srclang="kr"

                                    default
                                  >
                                </video>
                              </vue-plyr>
                              <vue-plyr
                                data-width="500"
                                data-height="300"
                                v-else-if="itemmedia.media.mediatype === 'audio'"
                              >
                                <audio>
                                  <source :src="`/uploadingDir/`+itemmedia.media.filename"
                                          type="audio/mp3">
                                  <source :src="`/uploadingDir/`+itemmedia.media.filename"
                                          type="audio/ogg">
                                </audio>
                              </vue-plyr>

                              <img
                                v-else
                                class="lazy"
                                data-width="500"
                                style="width:100%"
                                :data-src="`/uploadingDir/`+itemmedia.media.filename"
                                data-height="300"
                                data-uk-img
                                alt
                              >
                            </figure>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <div class="uk-card-body uk-flex uk-flex-center">
                        <div class="uk-width-1-2 uk-visible@s">
                          <div uk-slider="finite: true">
                            <div class="uk-position-relative">
                              <div class="uk-slider-container">
                                <ul
                                  class="tm-slider-items uk-slider-items uk-child-width-1-4 uk-grid uk-grid-small"
                                >
                                  <li
                                    v-for="(itemmedia, index) in question.question_datas"
                                    :key="index"
                                    :uk-slideshow-item="index"
                                  >
                                    <div class="tm-ratio tm-ratio-1-1">
                                      <a class="tm-media-box tm-media-box-frame">
                                        <figure class="tm-media-box-wrap">
                                          <vue-plyr
                                            data-width="500"
                                            data-height="300"
                                            v-if="itemmedia.media.mediatype === 'video'"
                                          >
                                            <video poster :src="`/uploadingDir/`+itemmedia.media.filename">
                                              <source
                                                :src="`/uploadingDir/`+itemmedia.media.filename"
                                                type="video/mp4"
                                                size="720"
                                              >
                                              <source
                                                :src="`/uploadingDir/`+itemmedia.media.filename"
                                                type="video/mp4"
                                                size="1080"
                                              >
                                              <track
                                                kind="captions"
                                                label="English"
                                                srclang="kr"

                                                default
                                              >
                                            </video>
                                          </vue-plyr>
                                          <vue-plyr
                                            data-width="500"
                                            data-height="300"
                                            v-else-if="itemmedia.media.mediatype === 'audio'"
                                          >
                                            <audio>
                                              <source
                                                :src="`/uploadingDir/`+itemmedia.media.filename"
                                                type="audio/mp3"
                                              >
                                              <source
                                                :src="`/uploadingDir/`+itemmedia.media.filename"
                                                type="audio/ogg"
                                              >
                                            </audio>
                                          </vue-plyr>

                                          <img
                                            v-else
                                            class="lazy"
                                            data-width="500"
                                            style="width:100%"
                                            :data-src="`/uploadingDir/`+itemmedia.media.filename"
                                            data-height="300"
                                            data-uk-img
                                            alt
                                          >
                                        </figure>
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                                <div>
                                  <a
                                    class="uk-position-center-left-out uk-position-small"
                                    href="#"
                                    uk-slider-item="previous"
                                    uk-slidenav-previous
                                  ></a>
                                  <a
                                    class="uk-position-center-right-out uk-position-small"
                                    href="#"
                                    uk-slider-item="next"
                                    uk-slidenav-next
                                  ></a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul class="uk-slideshow-nav uk-dotnav uk-hidden@s"></ul>
                      </div>
                    </div>
                  </div>

                  <div class="uk-text-right">
                    <rate
                      class="uk-padding-remove"
                      :length="5"
                      :value="totalrate"
                      :showcount="false"
                      :readonly="true"
                    />
                  </div>
                </fieldset>
              </div>
              <div class="uk-width-2-5@m">
                <header class="uk-comment-header uk-position-relative">
                  <h4>{{question.question}} ?</h4>
                  <hr>
                  <div>
                    <ul class="uk-list">
                      <li v-for="(itemanswer, index) in question.question_answers" :key="index">
                        <span
                          class="uk-text-muted">{{index+1}} - {{itemanswer.answer}} - {{itemanswer.media.filename}}</span>
                        <br>
                        <img
                          width="150"
                          class="lazy"
                          :data-src="`/uploadingDir/`+itemanswer.media.filename"
                          data-uk-img>
                      </li>
                    </ul>
                  </div>
                  <div class="uk-width-1-1">
                    <div class="uk-text-right">
                      <ul
                        class="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top"
                        uk-grid
                      >
                        <li>
                          <p style="color:#fff" class="uk-label uk-padding uk-padding-small">P</p>
                        </li>
                        <li>
                          <h1 style="font-size:25px; color:#666">
<!--                            <b>{{question.price|currency}}</b>-->
                            <b>{{question.price}}</b>
                          </h1>
                        </li>
                      </ul>
                    </div>
                    <div class="uk-flex" hidden>
                      <div class="uk-card-small uk-card-default uk-card-body">
                        <img
                          class="uk-border-circle"
                          src="../../assets/user_pro.png"
                          alt="Border pill"
                        >
                        <span>Name</span>
                        <br>
                        <a href="#">sdfsdf</a>
                      </div>
                    </div>

                    <p class="uk-text-justify">{{question.description}}</p>
                  </div>
                </header>
                <div>
                  <button
                    class="uk-margin-small uk-width-1-1 uk-button uk-button-default"
                    @click="addItem" >
                    <span uk-icon="cart" class="uk-icon"></span> Add cart
                  </button>
                </div>
                <div>
                  <button type="button" class="uk-margin-small uk-width-1-1 uk-button uk-button-primary">Purchased</button>
                </div>
              </div>
            </div>
            <hr>

            <div class="uk-grid">
              <div class="uk-width-3-5@m">
                <h4>Keywords</h4>

                <router-link
                  v-for="(keyitem, keyindex) in keywords"
                  :key="keyindex"
                  class="uk-margin-small-right uk-margin-small-bottom uk-button uk-button-default uk-button-small"
                  title="keyitem"
                  tag="a"
                  :to="{name: 'datamarket',
                      query: {
                mtype0: keyitem.datatype,
                keyword:keyitem.keyword
              }}"
                >
                  <span>{{keyitem.keyword}}</span>
                </router-link>

                <h4>Related Data</h4>

                <div
                  class="uk-grid-small uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m"
                  uk-grid="masonry: true"
                  uk-lightbox
                >
                  <div v-for="(item, index) in relateddatas" :key="index">
                    <div class="uk-card uk-card-default uk-card-body uk-padding-remove">
                      <a class="uk-inline" :href="item.imageurl" :data-caption="question.title">
                        <vue-plyr data-width="500" data-height="300" v-if="item.datatype == 2">
                          <video poster :src="item.imageurl">
                            <source :src="item.imageurl" type="video/mp4" size="720">
                            <source :src="item.imageurl" type="video/mp4" size="1080">
                            <track
                              kind="captions"
                              label="English"
                              srclang="kr"

                              default
                            >
                          </video>
                        </vue-plyr>
                        <vue-plyr data-width="500" data-height="300" v-else-if="item.datatype == 3">
                          <audio>
                            <source :src="item.imageurl" type="audio/mp3">
                            <source :src="item.imageurl" type="audio/ogg">
                          </audio>
                        </vue-plyr>
                        <img
                          v-else
                          :src="item.imageurl"
                          :data-src="item.imageurl"
                          width="500"
                          height="300"
                          :alt="item.title"
                          class="lazy"
                          data-uk-img
                        >
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-2-5@m">
                <app-review
                  v-if="question.id"
                  :type="type"
                  :id="question.id"
                  v-on:dataa="handleReviews"
                ></app-review>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </layout>
</template>


<script>
  import AnswersService from '@/services/AnswersService'
  import Layout from '../../layouts/Layout'
  import {mapActions, mapState} from 'vuex'
  import Review from '@/components/GlobViews/Review'

  export default {
    components: {
      Layout,
      appReview: Review
    },
    computed: {
      ...mapState(['isUserLoggedIn', 'user', 'route', 'isLoading'])
    },
    data() {
      return {
        question: {},
        loaderColor: '#5cb85c',
        loaderSize: '10px',
        keywords: null,
        relateddatas: null,
        totalrate: 0,
        type: 'question'
      }
    },
    watch: {
      $route(to, from) {
        if (from.params.questionId !== to.params.questionId) {
          AnswersService.showquestion(to.params.questionId).then(res => {
            console.log(res.data)
            this.question = res.data
          })
        }
      }
    },
    created() {
    },
    mounted() {
      const qId = this.route.params.questionId
      AnswersService.showquestion(qId).then(res => {
        console.log(res.data)
        this.question = res.data
      })

      if (this.question.mediatype === 'image') {
        this.question.datatype = '1'
      } else {
        this.question.datatype = '2'
      }
      this.loadRelatedData(this.question.datatype, this.question.keyword)
      AnswersService.getKeys({datatype: this.question.datatype}).then(res => {
        const {data} = res
        this.keywords = data
        if (!this.keywords.length) {
          return
        }
      })
    },
    methods: {
      handleReviews(e) {
        console.log('answer event ftw', e)
        this.totalrate = 0
        // eslint-disable-next-line no-unused-vars
        e.reviews.forEach((a, index) => {
          if (a.rate) this.totalrate += a.rate
        })

        if (e.reviews) {
          this.totalrate = this.totalrate / e.reviews.length
        }
      },
      loadRelatedData(datatype, keyword) {
        // console.log(datatype, keyword)
        AnswersService.getAnswersByPage({
          mtype: datatype,
          keyword: keyword,
          size: 6
        }).then(res => {
          const {data} = res
          this.relateddatas = data.content
          if (!this.relateddatas.length) {
            return
          }
        })
      },
      ...mapActions(['updateCart']),
      addItem() {
        const order = {
          item: Object.assign({}, this.question),
          quantity: 1,
          isAdd: true,
          type: 'question'
        }
        // console.log(order);
        this.updateCart(order)
      }
    }
  }
</script>

<style scoped>
  .soctial a {
    background: #0f6ecd;
    padding: 10px;
    margin: 0px;
    color: #fff;
    border-radius: 5px;
    width: 20px;
    text-align: center;
  }

  .checked {
    color: rgb(1, 176, 255);
  }
</style>
